import AppURLManager from "@config/AppURLManager";
import axios from "axios";
import { ListingFileReferencesDetail } from "types/taskgpt.types";

export const hasExtension = (fileName: string, exts: string[]) => {
    return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
}

export const getPreviewURLs = (images: ListingFileReferencesDetail[]) => {
    if(!images){
        return [];
    }
    
    return images.map( image => getAssetURL( image.fileId ) ) ;
}

export const getAssetURL = (fileId: string) => {
    return AppURLManager.Applications.assets + fileId;
}

export const getProfileImageUrl = (imageUrl: string | null | undefined): string | undefined => {
    if (!imageUrl) {
        return undefined;
    }

    if (imageUrl.startsWith("file_")) {
        return getAssetURL(imageUrl);
    }

    return imageUrl;
}

export const getProfileImageUrl1 = (imageUrl: string | null | undefined): string => {
    if (!imageUrl) {
        return '';
    }

    if (imageUrl.startsWith("file_")) {
        return getAssetURL(imageUrl);
    }

    return imageUrl;
}


export const downloadFileFromS3 = async (file: string) => {
    try {
        const response = await axios.get(getAssetURL(file), { responseType: 'blob' });
        // Step 2: Create a Blob URL and initiate the download
        const blobUrl = URL.createObjectURL(response.data);
        downloadFile(blobUrl, file);
    } catch (error) {
        console.error('Error:', error);
    }
}

export const downloadFile = (url: string, fileName: string = 'file') => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    
    // Append the anchor element to the document
    document.body.appendChild(link);
    
    // Programmatically trigger the click event on the anchor element
    link.click();
    
    // Remove the anchor element from the document
    document.body.removeChild(link);
    
    // Release the Blob URL
    URL.revokeObjectURL(url);
}