import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import routes from 'routes';
const FooterTwo = () => {
  return (
    <>
      <footer className="max-w-[1920px] flex items-center  flex-col pt-[50px] pb-6 border-b-[4px] border-solid border-[#F3F3F3] bg-[#071817]">
        {/* Footer Child */}
        {/* <div className="w-[90%]   flex items-center justify-around flex-col gap-9">
          <h2 className="text-4xl text-[#F8F8F8] font-semibold text-center leading-[72px]">
            Want to grow your business? <br /> let’s talk today.
          </h2>
          <div className="text-[22px] text-center [font-family:'Quicksand',Helvetica] font-bold  flex gap-3 items-center text-white">
            <div className="header_links_btn relative w-fit mt-[-1.00px] [font-family:'Quicksand',Helvetica] font-bold text-[16px] tracking-[0] leading-[24px] whitespace-nowrap border border-[#FFFFFF4A] px-14 py-3 rounded-lg cursor-pointer ">
              Sign Up
            </div>
          </div>
        </div> */}
        <div className="max-w-[1200px]  mx-auto w-full mt-10 flex items-center  justify-between ">
          <div className="w-full  flex justify-between max-md:flex-col max max-md:items-center">
            <div className="text-[18px] max-md:text-[10px] font-[Quicksand]">
              <div className="w-[100px]  mb-4">
                <Image src="/logoWhite.png" className=" fill-black" alt="logo" width={100} height={50} />
              </div>
              <p className=" font-normal text-sm text-center text-[#808080]">Copyright @2024. All Rights Reserved.</p>
            </div>
            <div className="text-[18px] max-md:text-[10px] font-[Quicksand]">
              <div className="flex list-none gap-4 justify-center  md:lg:justify-end">
                <li className="w-[32px] h-[32px] rounded-[4px]  flex items-center bg-[#192827]">
                  <a className="cursor-pointer w-full h-full flex items-center justify-center" href="#">
                    <Image src="/instagram-white.png" className=" fill-black" alt="instagram" width={18} height={18} />
                  </a>
                </li>
                <li className="w-[32px] h-[32px] rounded-[4px]  flex items-center justify-center bg-[#192827]">
                  <a className="cursor-pointer w-full h-full flex items-center justify-center" href="#">
                    <Image src="/twitter-white.png" className=" fill-black" alt="twitter" width={18} height={18} />
                  </a>
                </li>
                <li className="w-[32px] h-[32px] rounded-[4px]  flex items-center justify-center bg-[#192827]">
                  <a className="cursor-pointer w-full h-full flex items-center justify-center" href="#">
                    <Image src="/facebook-white.png" className=" fill-black" alt="facebook" width={10} height={15} />
                  </a>
                </li>
                <li className="w-[32px] h-[32px] rounded-[4px]  flex items-center justify-center bg-[#192827]">
                  <a className="cursor-pointer w-full h-full flex items-center justify-center" href="#">
                    <Image src="/pinterest-white.png" className=" fill-black" alt="pinterest" width={18} height={18} />
                  </a>
                </li>
              </div>
              <div className="flex col md:lg:flex  md:lg:justify-between items-center gap-9 mt-10">
                <div className="flex justify-between items-center gap-3  text-sm text-center text-white ">
                  <Link href={routes.PRIVACY} passHref>
                    <a className="cursor-pointer text-white">
                      <p>Privacy Policy </p>
                    </a>
                  </Link>
                  <Link href={routes.TERMS_AND_CONDITIONS} passHref>
                    <a className="cursor-pointer text-white">
                      <p>Terms of Service </p>
                    </a>
                  </Link>
                  <Link href={routes.COOKIE_POLICY} passHref>
                    <a className="cursor-pointer text-white">
                      <p>Cookies Settings </p>
                    </a>
                  </Link>
                  <Link href={routes.BLOG} passHref>
                    <a className="cursor-pointer text-white">
                      <p>Blogs </p>
                    </a>
                  </Link>
                  <p>Contact</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* //Footer */}

      {/* Privacy Policy Text */}
    </>
  );
};

export default FooterTwo;
