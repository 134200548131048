import resolveEnv, { PROD, DEV, LOCAL, TEST } from './resolveEnv';

export const env: 'PROD' | 'DEV' | 'LOCAL' | 'TEST' = resolveEnv();

const Applications = {
  [PROD]: {
    assets: 'https://assets.prod.viralocity.io/',
  },
  [TEST]: {
    assets: 'https://assets.dev.viralocity.io/',
  },
  [DEV]: {
    assets: 'https://assets.dev.viralocity.io/',
  },
  [LOCAL]: {
    assets: 'https://assets.dev.viralocity.io/',
  },
};

export default {
  Applications: Applications[env],
};
