export const PROD = 'PROD';
export const DEV = 'DEV';
export const TEST = 'TEST';
export const LOCAL = 'LOCAL';

export default function resolveEnv(): 'PROD' | 'DEV' | 'LOCAL' | 'TEST' {
  if (typeof window !== 'undefined') {
    if (window.location.host === 'viralocity.io' || window.location.host === 'www.viralocity.io') {
      return PROD;
    }

    if (window.location.host === 'test.viralocity.io') {
      return TEST;
    }

    if (window.location.host === 'dev.viralocity.io') {
      return DEV;
    }

    if (window.location.hostname === 'localhost') {
      return LOCAL;
    }
  }

  return LOCAL;
}
