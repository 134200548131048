import { Fragment, useEffect } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import useAuth from '@hooks/useAuth';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { FiMenu } from 'react-icons/fi';
import routes from 'routes';
import { useAccount } from 'providers/AccountsProvider';
import { RiLoader4Line } from 'react-icons/ri';
import { Avatar } from 'flowbite-react';
import { UserType } from 'queries/accounts.query';
import { getProfileImageUrl } from '@utils/file.util';

/* const user = {
    name: 'Tom Cook',
    email: 'tom@example.com',
    imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
} */

const navigation = [
  // { name: 'Influencer', href: '/influencers-signup', current: true },
  // { name: 'Brands', href: '/brands', current: false },
  { name: 'Pricing', href: '/pricing', current: false },
  // { name: 'Listings', href: '/listing', current: false },
  // { name: 'Campaigns', href: '/influencers-signup', current: false },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

interface Props{
  bgTransparent?:boolean
}

export default function Dashboard(props: Props) {
  const { loading, isLoggedIn, user, signout } = useAuth();

  const { image, type } = useAccount();
  const router = useRouter();

  const userNavigation = [{ name: 'My Hub', href: routes.MYHUB_DASHBOARD }];

  const logout = async () => {
    await signout();
    window.location.href = '/';
  };

  return (
    <Disclosure as="nav" className={`${props.bgTransparent?'bg-transparent':'bg-[#68c1b8]'} py-2`}>
      {({ open }) => (
        <>
          <div className="mx-auto max-w-8xl px-3 sm:px-0 lg:px-8 ">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Link href={routes.HOME}>
                    <img src="/Logo.svg" className="h-16 w-144 fill-black cursor-pointer" alt="" />
                  </Link>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <div className="mr-4 flex items-baseline space-x-4">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current
                            ? 'text-underline text-white hover:bg-white/20'
                            : 'text-white hover:text-white hover:text-white hover:bg-white/20',
                          'rounded-md px-2 py-2 text-base font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                    {/*{isLoggedIn && (*/}
                    {/*  <>*/}
                    {/*    <Link href={routes.ORDERS} passHref>*/}
                    {/*      <a className="text-white hover:text-white hover:text-white hover:bg-white/20 rounded-md px-2 py-2 text-base font-medium">*/}
                    {/*        Campaigns*/}
                    {/*      </a>*/}
                    {/*    </Link>*/}
                    {/*    <Link href={routes.MESSAGES} passHref>*/}
                    {/*      <a className="text-white hover:text-white hover:text-white hover:bg-white/20 rounded-md px-2 py-2 text-base font-medium">*/}
                    {/*        Messages*/}
                    {/*      </a>*/}
                    {/*    </Link>*/}
                    {/*  </>*/}
                    {/*)}*/}
                  </div>
                  {loading && <RiLoader4Line className="animate-spin text-white text-2xl" />}
                  {!loading && !isLoggedIn && (
                    <div className="mr-4 flex items-baseline space-x-4">
                      <div
                        className={`cursor-pointer inline-flex items-center justify-center gap-[10px] px-[32px] py-[10px] relative rounded-[24px] border-2 border-solid`}
                      >
                        <Link href="/login">
                          <div
                            className={`header_links_btn relative w-fit mt-[-1.00px] [font-family:'Quicksand',Helvetica] font-bold text-[16px] tracking-[0] leading-[24px] whitespace-nowrap text-white`}
                          >
                            Login
                          </div>
                        </Link>
                      </div>
                      <div
                        className={` font-['Quicksand'] font-bold leading-[24px] signup_btn inline-flex items-center justify-center gap-[10px] px-[32px] py-[10px] relative rounded-[24px] !bg-[#fff] !text=[#68c1b8]`}
                      >
                        <Link
                          href="/signup"
                          className={`relative w-fit mt-[-1.00px] [font-family:'Quicksand',Helvetica] font-bold text-[16px] tracking-[0] leading-[24px] whitespace-nowrap `}
                        >
                          Sign Up
                        </Link>
                      </div>
                    </div>
                  )}
                  {/* <button
                    type="button"
                    className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button> */}

                  {/* Profile dropdown */}
                  {isLoggedIn && (
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="relative flex max-w-xs border-1 border-white items-center rounded-full px-2 py-1 text-sm focus:outline-none">
                          <Avatar rounded size="xs" img={getProfileImageUrl(image)!} />
                          <Bars3Icon className="block h-6 w-5 text-white ml-1" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                          {type === UserType.INFLUENCER && (
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  className={classNames(
                                    active ? 'cursor-pointer bg-gray-100' : '',
                                    'cursor-pointer block px-4 py-2 text-sm text-gray-700'
                                  )}
                                  href={routes.CREATOR_HUB}
                                >
                                  Creator Hub
                                </a>
                              )}
                            </Menu.Item>
                          )}
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                className={classNames(
                                  active ? 'cursor-pointer bg-gray-100' : '',
                                  'cursor-pointer block px-4 py-2 text-sm text-gray-700'
                                )}
                                href={process.env.NEXT_PUBLIC_DASHBOARD_URL+'/dashboard/'}
                                target='_blank'
                                rel="noreferrer"
                              >
                                Brands Hub
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                className={classNames(
                                  active ? 'cursor-pointer bg-gray-100' : '',
                                  'cursor-pointer block px-4 py-2 text-sm text-gray-700'
                                )}
                                onClick={logout}
                              >
                                Signout
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 pt-0 text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'text-white' : 'text-white hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}

                {isLoggedIn && (
                      <>
                      <Disclosure.Button
                        as="a"
                        href={routes.ORDERS}
                        className={classNames(
                          'text-white hover:text-white block rounded-md px-3 py-2 text-base font-medium'
                        )}
                      >
                        Campaigns
                      </Disclosure.Button>

                      <Disclosure.Button
                        as="a"
                        href={routes.MESSAGES}
                        className={classNames(
                          'text-white hover:text-white block rounded-md px-3 py-2 text-base font-medium'
                        )}
                      >
                        Messages
                      </Disclosure.Button>
                      </>
                    )}
            </div>
            {isLoggedIn && (
              <div className="border-t border-gray-700 pb-3 pt-4">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <img className="h-10 w-10 rounded-full" src={getProfileImageUrl(image)!} alt="" />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium leading-none text-white">{user?.name}</div>
                    <div className="text-sm font-medium leading-none mt-2">{user?.email}</div>
                  </div>
                </div>
                <div className="mt-3 space-y-1 px-2">
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className="block rounded-md px-3 py-2 text-base font-medium text-white hover:text-white"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                  {type === UserType.INFLUENCER && (
                    <Disclosure.Button
                      as="a"
                      href={routes.CREATOR_HUB}
                      className="block rounded-md px-3 py-2 text-base font-medium text-white hover:text-white"
                    >
                      Creator Hub
                    </Disclosure.Button>
                  )}
                  <Disclosure.Button
                    as="a"
                    onClick={logout}
                    className="cursor-pointer block rounded-md px-3 py-2 text-base font-medium text-white hover:text-white"
                  >
                    Signout
                  </Disclosure.Button>
                </div>
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
